import React from 'react';
import { above, below } from 'src/components/global/mediaqueries';
import AspectWrapper from 'src/components/AspectWrapper';
import BergStarSVG from 'src/icons/BergStar';
import Heading from 'src/components/Heading';
import Image from 'src/components/Image';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Tag from 'src/components/typography/Tag';
import theme from 'src/styles/theme';

const ReferenceWrapper = styled('div')`
    display: block;

    ${above.tablet_s} {
        display: flex;
        height: auto;
        flex-direction: row;

        &:nth-of-type(odd) {
            flex-direction: row-reverse;
        }
    }
`;

const SingleProductImageWrapper = styled('div')`
    height: 100%;
    width: 100%;
    overflow: hidden;

    ${above.tablet_s} {
        width: 33.333%; //width: calc(4 * 100% / 12);
    }
`;

const ContentWrapper = styled('div')`
    position: relative;
    width: 100%;
    color: ${theme.color.white};

    display: flex;
    flex-direction: column;

    ${above.tablet_s} {
        justify-content: center;
    }

    ${below.tablet_s} {
        min-height: 100vw;
        ${theme.spacing.mobile._12('padding-left')};
        ${theme.spacing.mobile._12('padding-right')};
        ${theme.spacing.mobile._16('padding-top')};
        ${theme.spacing.mobile._40('padding-bottom')};
    }

    ${above.tablet_s} {
        flex: 1;
    }

    ${theme.spacing.tablet._20('padding')};
    ${theme.spacing.desktop._32('padding')}

    ${above.tablet_s} {
        padding-right: 0 !important;
    }
`;

const ContentTag = styled(Tag)`
    ${theme.spacing.mobile._24('margin-bottom')};

    ${above.tablet_s} {
        position: absolute;
        margin-bottom: 0;
    }

    ${theme.spacing.tablet._20('top')};
    ${theme.spacing.tablet._20('left')};
    ${theme.spacing.desktop._32('top')};
    ${theme.spacing.desktop._32('left')};
`;

const ContentHeading = styled(Heading)`
    line-height: 1.25em;

    ${theme.fontSize.sans.mobile._24};
    ${theme.fontSize.sans.tablet._24};
    ${theme.fontSize.sans.desktop._40};

    ${theme.spacing.mobile._16('margin-bottom')};
    ${theme.spacing.tablet._20('margin-bottom')};
    ${theme.spacing.desktop._24('margin-bottom')};

    ${above.tablet_s} {
        margin-right: 8.333vw; //calc(100vw / 12 * 1);
        line-height: 1.15em;
        margin-top: 50px;
    }
    ${above.desktop_s} {
        margin-top: 0;
        margin-right: calc(25% + 8px);
    }
`;

const ContentParagraph = styled('div')`
    line-height: 1.6em;
    font-size: 16px;

    ${above.tablet_s} {
        margin-right: 8.333vw; //calc(100vw / 12 * 1);
        margin-bottom: 50px;
    }
    ${above.desktop_s} {
        margin-right: 4.166vw; // calc(100vw / 12 * 2);
        margin-bottom: 0;
    }
`;

const ContentImage = styled(Image)`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
`;

const SvgWrapper = styled('div')`
    background-color: ${theme.color.productcard};
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;

    .star {
        width: 50%;
        height: 30%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
    }
`;

//Check if mobile and desktop images are availiable and returns image accordingly. If theres only one image availiable,
//it will be returned for both mobile/desktop.
const getImage = image => {
    return image.mobile.image.medium && image.desktop.image.medium
        ? [
              image.mobile.image.medium[0],
              {
                  ...image.desktop.image.medium[0],
                  media: `${above.tablet_s.replace('@media ', '')}`,
              },
          ]
        : image.desktop.image.medium
        ? image.desktop.image.medium
        : image.mobile.image.medium;
};

const ReferenceModule = ({ data }) => (
    <>
        {data.references.map((reference, index) => (
            <ReferenceWrapper style={{ backgroundColor: theme.color[reference.backgroundColor] }} key={index}>
                <SingleProductImageWrapper>
                    {reference.image.mobile.image.medium ? (
                        <AspectWrapper ratio={[1 / 1, null, 2 / 3]}>
                            <ContentImage
                                fluid={getImage(reference.image)}
                                style={{
                                    height: '100%',
                                    width: '100%',
                                    position: 'absolute',
                                    top: '0',
                                    left: '0',
                                }}
                            />
                        </AspectWrapper>
                    ) : (
                        <AspectWrapper ratio={[1 / 1, null, 2 / 3]}>
                            <SvgWrapper>
                                <BergStarSVG className="star" />
                            </SvgWrapper>
                        </AspectWrapper>
                    )}
                </SingleProductImageWrapper>

                <ContentWrapper>
                    <ContentTag>{reference.tag}</ContentTag>
                    <ContentHeading>{reference.heading}</ContentHeading>
                    <ContentParagraph>{reference.paragraph}</ContentParagraph>
                </ContentWrapper>
            </ReferenceWrapper>
        ))}
    </>
);

ReferenceModule.propTypes = {
    data: PropTypes.shape({
        references: PropTypes.arrayOf(
            PropTypes.shape({
                backgroundColor: PropTypes.string,
                heading: PropTypes.string,
                image: PropTypes.object,
                paragraph: PropTypes.string,
                tag: PropTypes.string,
            })
        ),
    }),
};

ReferenceModule.defaultProps = {
    data: {
        references: [],
    }
};

export default ReferenceModule;
